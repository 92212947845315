export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('moonpay', {
    init(params) {
      return window.MoonPayWebSdk.init({
        flow: 'buy',
        environment: nuxtApp.$config.public.MOONPAY_ENV,
        variant: 'overlay',
        params: {
          apiKey: nuxtApp.$config.public.MOONPAY_KEY,
          ...params,
        },
        debug: true,
      });
    },
  });
});
