export const walletModalModeTypes = [
  'Deposit',
  'Withdraw',
];

export const walletModalSubModeTypes = [
  'web3',
  'custodial',
  'cards',
];
