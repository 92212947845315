/*
Transformation functions exposed as plugin to use throughout the app
*/
import BigNumber from 'bignumber.js';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      truncateNumber: (num, decimals = 1, addCommas = false) => {
        const amount = new BigNumber(num);

        if (amount.isNaN() || (!amount.isZero() && amount.decimalPlaces(decimals).isZero())) {
          return 0;
        }

        const formatted = amount.toFormat({
          groupSeparator: '',
          decimalSeparator: '.',
          fractionGroupSeparator: '',
        });
        const convertedNum = (!amount.isInteger()) ? (+formatted.slice(0, (formatted.indexOf('.')) + (decimals + 1))) : num;
        if (addCommas) {
          return convertedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          return convertedNum;
        }
      },

      // Format number with added symbol
      formatNumber: (num, digits) => {
        const lookup = [
          { value: 1, symbol: '', },
          { value: 1e3, symbol: 'K', },
          { value: 1e6, symbol: 'M', },
          { value: 1e9, symbol: 'BN', },
          { value: 1e12, symbol: 'TN', },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        const item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        const suffix = item?.symbol ? `${item.symbol}` : '';
        return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + suffix : '0';
      },

      formatMoney: (amount, currency = 'ETH', alwaysShowFractions) => {
        let minimumFractionDigits = Number.isInteger(Number(amount)) ? 0 : undefined;

        if (alwaysShowFractions) {
          minimumFractionDigits = undefined;
        }

        const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency, });
        const decimals = numberFormat.formatToParts(1)
        .find(part => part.type === 'fraction')
        ?.value.length ?? 18;

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currencyDisplay: 'symbol',
          currency,
          minimumFractionDigits,
          maximumFractionDigits: decimals,
        });
        const parts = formatter.formatToParts(amount);

        const currencyPart = parts.find(p => p.type === 'currency');

        if (currencyPart) {
          switch (currency) {
            case 'ETH':
              currencyPart.value = '';
              break;
          }
        }

        return parts.map(p => p.value).join('');
      },

      // Pluralize strings - add 's' depending on accompanying number
      pluralize: (count, noun, suffix = 's', returnCount = true) => {
        return `${returnCount ? count : ''} ${noun}${count !== 1 ? suffix : ''}`;
      },

      toSingleUnitTimeShort: (seconds) => {
        if (!seconds) { return ''; }
        const days = Math.floor(seconds / (3600 * 24));
        const hours = Math.floor((seconds / 3600) % 24);
        const minutes = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);

        if (days > 0) { return `${days} d`; }
        if (hours > 0) { return `${hours} h`; }
        if (minutes > 0) { return `${minutes} min`; }
        return `${s} s`;
      },

      toMinutes: (seconds) => {
        if (!seconds) { return ''; }
        const format = val => `0${val}`.slice(-2);
        const days = Math.floor(seconds / (3600 * 24));
        const hours = Math.floor((seconds / 3600) % 24);
        const minutes = Math.floor((seconds % 3600) / 60);
        const parts = [minutes, Math.floor(seconds % 60),];
        if (hours > 0) {
          parts.unshift(hours);
        }
        if (days > 0) {
          let returnMsg = `${days} day${days > 1 ? 's' : ''}`;
          if (hours) {
            returnMsg += ` ${hours} hr${hours > 1 ? 's' : ''}`;
          }
          return returnMsg;
        }
        return parts.map(format).join(':');
      },

      removeTrailingSlash: (str) => {
        return str.replace(/\/+$/, '');
      },

      trimTimeUnit: (str) => {
        const newStr = str.replace('minutes', 'mins').replace('minute', 'min').replace('seconds', 'sec').replace('second', 'sec').replace('hour', 'hr').replace('hours', 'hrs');
        return newStr;
      },

      toOrdinals: (num) => {
        const s = ['th', 'st', 'nd', 'rd',];
        const v = num % 100;
        return num + (s[(v - 20) % 10] || s[v] || s[0]);
      },

      toBigNumber: (num) => {
        return new BigNumber(num);
      },

    },
  };
});
