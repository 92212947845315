// Functions and state changes required for logged in user browsing the platform
import { storeToRefs } from 'pinia';

import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { useBankingStore } from '@/store/banking';
import { useCryptoStore } from '@/store/crypto';
import { useWebsocketStore } from '@/store/websocket';
import { useNotificationsStore } from '@/store/notifications';
import { useDiamondsStore } from '@/store/diamonds';
import { useSocialStore } from '@/store/social';

export async function usePlatformStarter() {
  const authStore = useAuthStore();
  const {
    isUserLogged,
  } = storeToRefs(authStore);
  const userStore = useUserStore();
  const bankingStore = useBankingStore();
  const cryptoStore = useCryptoStore();
  const diamondsStore = useDiamondsStore();
  const websocketStore = useWebsocketStore();
  const notificationsStore = useNotificationsStore();
  const socialStore = useSocialStore();

  if (!isUserLogged.value) { return; }

  // Individual checks
  if (userStore.userData === null) {
    userStore.userInit();
    await userStore.getUserData();
  }

  if (!cryptoStore.initiated) {
    cryptoStore.cryptoInit();
  }

  if (!diamondsStore.initiated) {
    diamondsStore.diamondsInit();
  }

  // Login to websocket
  if (websocketStore.ws && !websocketStore.loggedin) {
    websocketStore.websocketLogin();
  }

  if (websocketStore.ws && !bankingStore.initiated) {
    bankingStore.bankingInit();
  }

  if (websocketStore.ws && !socialStore.initiated) {
    socialStore.socialInit();
  }

  // Start notification service
  if (!notificationsStore.initiated) {
    notificationsStore.initCountListener();
  }
}
